<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark color="">
      <v-card-title>
        <div class="text--h6">{{ $t("resellers.promotion.title") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon text @click="dialog.display = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-select
          :label="$t('resellers.fields.promotion.title')"
          persistent-hint
          :hint="$t('resellers.fields.promotion.help')"
          :items="items.promotions"
          :loading="loading.promotion"
          item-text="name"
          item-value="id"
          v-model="form.promotions"
          multiple
          chips
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="save()"
          :loading="loading.save"
          color="third"
          text
          :disabled="form.promotions.length < 1"
          >{{ $t("btn.assign") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapActions } from "vuex";
export default {
  created() {
    this.getPromotions();
  },
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    reseller: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    loading: {
      promotion: false,
      save: false,
    },
    items: {
      promotions: [],
    },
    form: {
      promotions: [],
    },
  }),
  methods: {
    async getPromotions() {
      this.loading.promotion = true;
      try {
        const response = await this.request({
          url: "api/v1/promotions",
          params: {
            open: true,
          },
        });
        this.items.promotions = response.data.items;
      } catch (error) {
        //empty
      }
      this.loading.promotion = false;
    },
    async save() {
      if (!this.form.promotions) return;

      this.loading.save = true;
      try {
        await this.request({
          url: `api/v1/resellers/${this.reseller.id}/promotions`,
          method: "PUT",
          data: this.form,
          messages: {
            200: this.$t("resellers.promotion.assign.success"),
            404: this.$t("resellers.errors.not_found"),
            500: true,
          },
        });
        this.form.promotions = [];
      } catch (error) {
        // epmpty
      }
      this.loading.save = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
};
</script>