<template>
  <div>
    <reseller></reseller>
  </div>
</template>

<script>
import Reseller from "./../../components/resellers/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("resellers.title")
    };
  },
  components: {
    Reseller
  }
};
</script>