<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("resellers.detail.title") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon text>
          <v-icon @click="dialog.display = false">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div v-if="reseller.id">
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("commons.created.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $moment(reseller.created).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.identifier.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ reseller.identifier }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.pin.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ reseller.pin }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :loading="loading.regeneratePin" icon text color="third"
                      @click="regeneratePin()"><v-icon v-bind="attrs" v-on="on"
                        >mdi-refresh</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>{{ $t('resellers.detail.actions.regeneratePin') }}</span>
                </v-tooltip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.type.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $t("resellers.fields.type." + reseller.type) }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.enabled.title2")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip
                  color="success"
                  class="table-chip"
                  v-if="reseller.enabled"
                  >{{ $t("resellers.fields.enabled.enabled") }}</v-chip
                >
                <v-chip v-else color="danger" class="table-chip">{{
                  $t("resellers.fields.enabled.disabled")
                }}</v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.status.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip
                  color="success"
                  class="table-chip"
                  v-if="!reseller.lockPin"
                  >{{ $t("resellers.fields.status.enabled") }}</v-chip
                >
                <v-chip v-else color="danger" class="table-chip">{{
                  $t("resellers.fields.status.disabled")
                }}</v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.name.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ reseller.name }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.location.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span v-if="reseller.localisation">{{
                  reseller.localisation
                }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.district.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span v-if="reseller.quarter">{{ reseller.quarter }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.wholesale.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span v-if="reseller.depot">{{ reseller.depot }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.deliveryTeam.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span v-if="reseller.assignedDepositSeller">{{
                  reseller.assignedDepositSeller
                }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div v-if="reseller.parent" class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.parent.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <span>{{ reseller.owner }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div v-if="reseller.owner" class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.owner.title2")
                }}</span>
              </div>
              <div class="ml-auto">
                <span>{{ reseller.owner }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <regenerate-pin-confirm
      :dialog="modal.regeneratePin"
      @confirm="confirmRegeneratePin"
    ></regenerate-pin-confirm>
  </v-dialog>
</template>


<script>
import { mapActions } from "vuex";
import RegeneratePinConfirm from "./../commons/confirm.vue";
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    reseller: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    modal: {
      regeneratePin: {
        display: false,
        message: null,
      },
    },
    loading: {
      regeneratePin: false
    }
  }),
  methods: {
    regeneratePin() {
      this.modal.regeneratePin.message = this.$t('resellers.regeneratePin.confirm')
      this.modal.regeneratePin.display = true;
    },
    async confirmRegeneratePin() {
      try {
        this.loading.regeneratePin = true;
        const response = await this.request({
          url: `api/v1/resellers/${this.reseller.id}/regenerate-pin`,
          method: 'PUT',
          messages: {
            500: true,
            404: this.$t('resellers.errors.not_found'),
            200: this.$t('resellers.regeneratePin.success')
          }
        });
        this.reseller.pin = response.data.pin;
      } catch (error) {
        // empty
      }
      this.loading.regeneratePin = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  components: {
    RegeneratePinConfirm,
  },
};
</script>