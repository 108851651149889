<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("resellers.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilters()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div
          :class="{
            'mr-auto': $vuetify.breakpoint.xsOnly,
            'ml-auto': $vuetify.breakpoint.smAndUp,
          }"
          class="mb-2"
        >
          <v-btn
            color="primary"
            class="mr-2"
            :to="$i18n.getRouteLink('reseller-upload')"
            v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
          >
            <v-icon left>mdi-file-upload-outline</v-icon>
            <span>{{ $t("btn.uploadFile") }}</span>
          </v-btn>
          <v-btn
            color="primary"
            :to="$i18n.getRouteLink('reseller-add')"
            v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("resellers.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.reseller", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
        dark
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="(item, k) in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td style="min-width: 110px">{{ item.identifier }}</td>
                  <td>
                    <v-chip
                      v-if="item.type === 'RETAILER'"
                      class="table-chip"
                      color="third"
                      light
                      >{{ $t("resellers.fields.type.RETAILER") }}</v-chip
                    >
                    <v-chip
                      v-else-if="item.type === 'SUB_RETAILER'"
                      class="table-chip"
                      color="third"
                      light
                      >{{ $t("resellers.fields.type.SUB_RETAILER") }}</v-chip
                    >
                  </td>
                  <td>
                    <v-chip
                      color="success"
                      class="table-chip"
                      v-if="item.enabled"
                      >{{ $t("resellers.fields.enabled.enabled") }}</v-chip
                    >
                    <v-chip v-else color="danger" class="table-chip">{{
                      $t("resellers.fields.enabled.disabled")
                    }}</v-chip>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.depot }}</td>
                  <td>{{ item.localisation }}</td>
                  <td style="min-width: 175px" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          color="third"
                          @click="getDetail(item)"
                        >
                          <v-icon color="third">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("resellers.list.actions.detail") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          color="third"
                          @click="lockOrUnlockApiConnexion(item, k)"
                        >
                          <v-icon v-if="item.lockPin" color="third"
                            >mdi-lock-outline</v-icon
                          >
                          <v-icon color="third" v-else
                            >mdi-lock-open-variant-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span v-if="item.lockPin">{{
                        $t("resellers.list.actions.unlockApiConnexion")
                      }}</span>
                      <span v-else>{{
                        $t("resellers.list.actions.lockApiConnexion")
                      }}</span>
                    </v-tooltip>
                    <template
                      v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="addPromo(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            color="third"
                          >
                            <v-icon color="third">mdi-sale</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("resellers.list.actions.addPromo") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :to="{
                              name: 'reseller-edit',
                              params: { id: item.id },
                            }"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            color="third"
                          >
                            <v-icon color="third">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("resellers.list.actions.edit") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="deleteReseller(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            color="third"
                          >
                            <v-icon color="third">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("resellers.list.actions.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <reseller-filter
      :dialog="dialog.filter"
      @applyFilter="filterItems"
    ></reseller-filter>
    <reseller-confirm
      :dialog="dialog.delete"
      @confirm="confirmDeleteReseller()"
    ></reseller-confirm>
    <reseller-detail
      :dialog="dialog.detail"
      :reseller="reseller"
    ></reseller-detail>
    <reseller-confirm
      :dialog="dialog.lockOrUnlockApiConnexion"
      @confirm="confirmLockOrUnlockApiConnexion()"
    >
    </reseller-confirm>
    <reseller-promotion
      :dialog="dialog.promotion"
      :reseller="reseller"
    ></reseller-promotion>
  </div>
</template>


<script>
import ListMixin from "./../../mixins/commons/list";
import ResellerDetail from "./detail";
import ResellerFilter from "./filter";
import ResellerConfirm from "./../commons/confirm";
import ResellerPromotion from "./promotion.vue";
import { mapActions } from "vuex";

export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/v1/resellers",
    dialog: {
      filter: {
        display: false,
      },
      delete: {
        display: false,
        message: null,
      },
      detail: {
        display: false,
      },
      lockOrUnlockApiConnexion: {
        display: false,
        message: null,
      },
      promotion: {
        display: false,
      },
    },
    form: {
      name: null,
      identifier: null,
      localisation: null,
      parent: null,
      owner: null,
      pin: null,
      enabled: true,
    },
    reseller: {},
    selectedResellerIndex: null,
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("resellers.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("resellers.fields.identifier.title"),
          align: "start",
          sortable: true,
          value: "identifier",
          width: 110,
        },
        {
          text: this.$t("resellers.fields.type.title"),
          align: "start",
          sortable: true,
          value: "type",
          width: 80,
        },
        {
          text: this.$t("resellers.fields.enabled.title2"),
          align: "start",
          sortable: true,
          value: "enabled",
          width: 80,
        },
        {
          text: this.$t("resellers.fields.name.title"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("resellers.fields.wholesale.title"),
          align: "start",
          sortable: true,
          value: "depot",
          width: 130,
        },
        {
          text: this.$t("resellers.fields.location.title"),
          align: "start",
          sortable: true,
          value: "localisation",
          width: 130,
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: null,
          width: 175,
        },
      ];
    },
    getDetail(reseller) {
      this.dialog.detail.display = true;
      this.reseller = reseller;
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    deleteReseller(reseller) {
      this.reseller = reseller;
      this.dialog.delete.message = this.$t("resellers.delete.confirm.title");
      this.dialog.delete.display = true;
    },
    lockOrUnlockApiConnexion(reseller, index) {
      this.reseller = reseller;
      this.selectedResellerIndex = index;

      this.dialog.lockOrUnlockApiConnexion.display = true;
      if (reseller.lockPin) {
        this.dialog.lockOrUnlockApiConnexion.message = this.$t(
          "resellers.fields.pin.unlock"
        );
      } else {
        this.dialog.lockOrUnlockApiConnexion.message = this.$t(
          "resellers.fields.pin.lock"
        );
      }
    },
    async confirmLockOrUnlockApiConnexion() {
      const locked = this.reseller.lockPin;
      const url = `api/v1/resellers/${this.reseller.id}/${
        locked ? "unlock" : "lock"
      }-pin`;
      try {
        await this.request({
          url: url,
          method: "PUT",
          messages: {
            404: this.$t("resellers.errors.not_found"),
            500: true,
            200: this.$t(
              `resellers.${
                locked ? "unlockApiConnexion" : "lockApiConnexion"
              }.success`
            ),
          },
        });
        this.$objectPath.set(
          this.items,
          `${this.selectedResellerIndex}.lockPin`,
          !this.reseller.lockPin
        );
      } catch (error) {
        // empty
      }
    },
    async confirmDeleteReseller() {
      if (!this.reseller.id) return;
      try {
        await this.request({
          url: `api/v1/resellers/${this.reseller.id}`,
          method: "DELETE",
          messages: {
            204: this.$t("resellers.delete.success"),
            500: true,
          },
        });
        this.getItems();
      } catch (error) {
        // empty
      }
    },
    addPromo(item) {
      this.dialog.promotion.display = true;
      this.reseller = item;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  components: {
    ResellerFilter,
    ResellerConfirm,
    ResellerDetail,
    ResellerPromotion,
  },
};
</script>