var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-h6 font-weight-medium third--text"},[_vm._v(" "+_vm._s(_vm.$t("resellers.title"))+" ")])]),_c('v-card',{staticClass:"rounded-md mb-4",attrs:{"dark":"","color":"fifth"}},[_c('div',{staticClass:"d-flex align-center justify-start px-4 py-3 flex-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("btn.refresh"))+" ")],1),_c('v-btn',{attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.getFilters()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t("btn.filter"))+" ")],1),_c('div',{staticClass:"mb-2",class:{
          'mr-auto': _vm.$vuetify.breakpoint.xsOnly,
          'ml-auto': _vm.$vuetify.breakpoint.smAndUp,
        }},[(_vm.$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']))?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","to":_vm.$i18n.getRouteLink('reseller-upload')}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-upload-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("btn.uploadFile")))])],1):_vm._e(),(_vm.$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']))?_c('v-btn',{attrs:{"color":"primary","to":_vm.$i18n.getRouteLink('reseller-add')}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("btn.add")))])],1):_vm._e()],1)],1)]),_c('v-card',{attrs:{"dark":"","color":"fifth"}},[_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"third--text"},[_vm._v(_vm._s(_vm.$t("resellers.list.title")))])]),_c('v-spacer'),(_vm.meta.total)?_c('v-btn',{attrs:{"outlined":"","color":"third"}},[_vm._v(_vm._s(_vm.$tc("counter.reseller", _vm.meta.total, { count: _vm.$utils.pad(_vm.meta.total, 2), })))]):_vm._e()],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":"","dark":"","server-items-length":_vm.meta.total},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
        var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,k){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"160px"}},[_c('span',[_vm._v(_vm._s(_vm.$moment(item.created).format("YYYY-MM-DD HH:mm:ss")))])]),_c('td',{staticStyle:{"min-width":"110px"}},[_vm._v(_vm._s(item.identifier))]),_c('td',[(item.type === 'RETAILER')?_c('v-chip',{staticClass:"table-chip",attrs:{"color":"third","light":""}},[_vm._v(_vm._s(_vm.$t("resellers.fields.type.RETAILER")))]):(item.type === 'SUB_RETAILER')?_c('v-chip',{staticClass:"table-chip",attrs:{"color":"third","light":""}},[_vm._v(_vm._s(_vm.$t("resellers.fields.type.SUB_RETAILER")))]):_vm._e()],1),_c('td',[(item.enabled)?_c('v-chip',{staticClass:"table-chip",attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.$t("resellers.fields.enabled.enabled")))]):_c('v-chip',{staticClass:"table-chip",attrs:{"color":"danger"}},[_vm._v(_vm._s(_vm.$t("resellers.fields.enabled.disabled")))])],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.depot))]),_c('td',[_vm._v(_vm._s(item.localisation))]),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"175px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"third"},on:{"click":function($event){return _vm.getDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.detail")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"third"},on:{"click":function($event){return _vm.lockOrUnlockApiConnexion(item, k)}}},'v-btn',attrs,false),on),[(item.lockPin)?_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-lock-outline")]):_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-lock-open-variant-outline")])],1)]}}],null,true)},[(item.lockPin)?_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.unlockApiConnexion")))]):_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.lockApiConnexion")))])]),(_vm.$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"third"},on:{"click":function($event){return _vm.addPromo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-sale")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.addPromo")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"to":{
                            name: 'reseller-edit',
                            params: { id: item.id },
                          },"x-small":"","icon":"","color":"third"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"third"},on:{"click":function($event){return _vm.deleteReseller(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"third"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("resellers.list.actions.delete")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('reseller-filter',{attrs:{"dialog":_vm.dialog.filter},on:{"applyFilter":_vm.filterItems}}),_c('reseller-confirm',{attrs:{"dialog":_vm.dialog.delete},on:{"confirm":function($event){return _vm.confirmDeleteReseller()}}}),_c('reseller-detail',{attrs:{"dialog":_vm.dialog.detail,"reseller":_vm.reseller}}),_c('reseller-confirm',{attrs:{"dialog":_vm.dialog.lockOrUnlockApiConnexion},on:{"confirm":function($event){return _vm.confirmLockOrUnlockApiConnexion()}}}),_c('reseller-promotion',{attrs:{"dialog":_vm.dialog.promotion,"reseller":_vm.reseller}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }